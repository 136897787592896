import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Grid,Box,AspectImage,AspectRatio,Card,Button,Image,Text, Paragraph } from 'theme-ui'


function BookPost ({data}){
    const book = data.allContentfulBooks.edges[0].node
    return(
        <Layout>
        <Grid className="book-post" >
            <Box className="thumbnail">
            <img src={book.books.fixed.src} />
            </Box>
          <Box className="books-excerpt">
            <h4 dangerouslySetInnerHTML={{__html: book.title}} />
            <p  dangerouslySetInnerHTML={{__html: book.introduction.introduction}} />
            <Button  className="books-excerpt-button"> Buy me </Button>
          </Box>
        </Grid>

        </Layout>
    )
}

export default BookPost

export const query = graphql`
    query($slug: String!){
        allContentfulBooks(filter: { slug: { eq: $slug } }) {
            edges {
              node {
                title
                contentful_id
                introduction {
                  introduction
                }
                books {
                    fixed(width: 150, height: 250)  {
                        src
                    }
                }
              }
            }
          }
    }
`